'use client';

import colors from '@/constants/theme/colors';
import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

export interface InfoTagProps {
  boxProps?: Omit<BoxProps, 'children'>;
  textProps?: TextProps;
  info: string;
}
const InfoTag: FC<InfoTagProps> = ({ boxProps, textProps, info }) => {
  return (
    <Box
      bgColor={colors.white}
      p="0.25rem 0.75rem"
      w="fit-content"
      {...boxProps}
    >
      <Text
        color={colors.primary[300]}
        fontSize={{ base: '0.75rem', '2xl': '0.875rem' }}
        lineHeight={{ base: '1rem', '2xl': '1.25rem' }}
        fontWeight="600"
        letterSpacing={{ base: '-0.06px', '2xl': 'normal' }}
        {...textProps}
      >
        {info}
      </Text>
    </Box>
  );
};

export default InfoTag;
