import colors from '@/constants/theme/colors';
import { Text } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { ArrowRightSLineIcon } from '@/components/Icons';
import Link from 'next/link';
import DisplayFlex from '@/components/UI/DisplayFlex';

interface SectionTitleProps {
  title: string;
  link?: string;
}
const SectionTitle: FC<SectionTitleProps> = ({ title, link }) => {
  return (
    <SectionTitleWrapper link={link}>
      <DisplayFlex
        py={{ base: '0.25rem', '2xl': '0.25rem' }}
        pl={{ base: '0.75rem', '2xl': '0.75rem' }}
        pr={{ base: '0.5rem', '2xl': '0.5rem' }}
        cursor={!!link ? 'pointer' : 'auto'}
        userSelect="none"
        gap="0.25rem"
        alignItems="center"
        color={colors.primary[300]}
        bgColor={colors.white}
        w="fit-content"
      >
        <Text
          fontSize={{ base: '1rem', '2xl': '1.125rem' }}
          fontWeight="600"
          lineHeight="1.5rem"
        >
          {title}
        </Text>
        {!!link && <ArrowRightSLineIcon size="1.25rem" />}
      </DisplayFlex>
    </SectionTitleWrapper>
  );
};

interface SectionTitleWrapperProps extends PropsWithChildren {
  link?: string;
}
const SectionTitleWrapper: FC<SectionTitleWrapperProps> = ({
  link,
  children,
}) => {
  if (!!link) return <Link href={link}>{children}</Link>;

  return <>{children}</>;
};

export default SectionTitle;
