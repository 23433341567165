'use client';

import { useBreakpoint } from '@chakra-ui/react';

const useMobile = () => {
  const breakpoint = useBreakpoint();
  const isMobile = ['base', 'sm', 'md'].includes(breakpoint);

  return { isMobile, breakpoint };
};

export default useMobile;
