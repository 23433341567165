'use client';

import colors from '@/constants/theme/colors';
import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

interface DisplayFlexProps extends FlexProps {}
const DisplayFlex: FC<DisplayFlexProps> = ({ children, ...flexProps }) => {
  return (
    <Flex bgColor={colors.white} {...flexProps}>
      {children}
    </Flex>
  );
};

export default DisplayFlex;
