'use client';

import { Flex, Box, FlexProps, TextProps, Text } from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import { IProductItem } from '@/types/product';
import { PATH } from '@/constants/paths';
import {
  useCurrencyCodeSelector,
  useCurrencyExchangeRateSelector,
} from '@/store/slices/app';
import Link from 'next/link';
import Image, { ImageProps } from 'next/image';
import {
  displayProductVariantMetal,
  formatPrice,
  getPrimaryMetal,
  getSecondaryMetal,
} from '@/utils/product';
import InfoTag, { InfoTagProps } from '@/components/InfoTag';
import colors from '@/constants/theme/colors';
import { ArrowRightSLineIcon } from '@/components/Icons';
import { IAppProductVariant } from '@/types/api/app/product';
import useMobile from '@/hooks/useMobile';

export interface SimpleProductItemProps {
  productItem: IProductItem;
  parentPath?: string;
  onClick?: () => void;
  containerProps?: FlexProps;
  infoTextProps?: TextProps;
  imageProps?: Partial<ImageProps>;
}
const SimpleProductItem: FC<SimpleProductItemProps> = ({
  productItem,
  parentPath = PATH.shopNewArrivals,
  containerProps,
  infoTextProps,
}) => {
  const { isMobile } = useMobile();
  const selectedCurrencyCode = useCurrencyCodeSelector();
  const currencyExchangeRate = useCurrencyExchangeRateSelector();

  const { productVariant, priceCurrency, product } = productItem;
  const {
    metal,
    sku,
    price,
    photoOrVideoUrls,
    hoveringPhotoUrls,
    title: variantTitle,
  } = productVariant;
  const { title } = product;

  const currency = selectedCurrencyCode ?? priceCurrency;
  const displayPrice = `${formatPrice(currency)(
    price * currencyExchangeRate,
    undefined,
    undefined,
    2
  )}`;

  const [imgUrl, setImgUrl] = useState(photoOrVideoUrls?.[0] ?? '');

  const displayName = useMemo(() => {
    return variantTitle ?? title;
  }, [title, variantTitle]);

  return (
    <Link
      href={`${parentPath}/${productItem.slug}?sku=${sku}`}
      draggable={false}
    >
      <Flex
        flexDir="column"
        w={{ base: '18rem', xl: '21.25rem' }}
        h={{ base: '24rem', xl: '28.375rem' }}
        bgColor={colors.white}
        position="relative"
        {...containerProps}
        onMouseOver={() => {
          if (isMobile) return;
          const secondImgUrl = hoveringPhotoUrls?.[0];
          if (!!secondImgUrl) setImgUrl(secondImgUrl);
        }}
        onMouseLeave={() => {
          if (isMobile) return;
          setImgUrl(photoOrVideoUrls?.[0] ?? '');
        }}
        onClick={() => {
          if (!isMobile) return;
          const firstImgUrl = photoOrVideoUrls?.[0];
          const secondImgUrl = hoveringPhotoUrls?.[0];
          if (!secondImgUrl || !firstImgUrl) return;
          setImgUrl((prev) =>
            prev === firstImgUrl ? secondImgUrl : firstImgUrl
          );
        }}
      >
        <Box
          bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 74.5%, rgba(0, 0, 0, 0.03) 100%)"
          position="absolute"
          w="100%"
          h="100%"
          zIndex="10"
        />
        <Flex
          flexDir="column"
          position="relative"
          w="100%"
          h="100%"
          justifyContent="flex-end"
        >
          {!!imgUrl ? (
            <Box position="absolute" transitionProperty="all" w="100%" h="100%">
              <Image
                alt={displayName}
                src={imgUrl}
                fill
                sizes="(max-width: 992px) 175px, 270px"
                style={{ objectFit: 'cover' }}
                priority
                loading="eager"
                quality={100}
                unoptimized
              />
            </Box>
          ) : (
            <Flex
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              bgColor={colors.grey[100]}
            >
              <Text color={colors.primary[300]} textTransform="uppercase">
                no image
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex flexDir="column" zIndex="10" gap="0.5rem" p="0.5rem">
          <Flex
            alignItems="center"
            bgColor={colors.white}
            w="fit-content"
            pr="0.5rem"
          >
            <InfoTag info={displayName} textProps={infoTextProps} />
            <ArrowRightSLineIcon size="1.25rem" />
          </Flex>
          <Flex alignItems="flex-start" gap="0.5rem">
            <MetalInfoTags metal={metal} infoTextProps={infoTextProps} />
            <InfoTag info={displayPrice} textProps={infoTextProps} />
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

interface MetalInfoTagsProps {
  metal: IAppProductVariant['metal'];
  infoTextProps?: InfoTagProps['textProps'];
}
const MetalInfoTags: FC<MetalInfoTagsProps> = ({ metal, infoTextProps }) => {
  const primaryMetal = getPrimaryMetal(metal);
  const secondaryMetal = getSecondaryMetal(metal);

  return (
    <Flex alignItems="center">
      <InfoTag
        info={displayProductVariantMetal(primaryMetal).value}
        textProps={{
          ...infoTextProps,
          color: displayProductVariantMetal(primaryMetal).color,
        }}
      />
      {!!secondaryMetal && (
        <InfoTag
          info={displayProductVariantMetal(secondaryMetal).value}
          textProps={{
            ...infoTextProps,
            color: displayProductVariantMetal(secondaryMetal).color,
          }}
          boxProps={{
            pl: 0,
          }}
        />
      )}
    </Flex>
  );
};

export default SimpleProductItem;
