import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Image from 'next/image';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

interface DragToScrollContainerProps extends PropsWithChildren {
  containerProps?: Partial<FlexProps>;
}
const DragToScrollContainer = forwardRef<
  HTMLDivElement,
  DragToScrollContainerProps
>(({ containerProps, children }, ref) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const innerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => innerRef.current!);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const ele = innerRef.current;
      if (!ele) {
        return;
      }
      const startPos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        ele.scrollTop = startPos.top - dy;
        ele.scrollLeft = startPos.left - dx;
        updateCursor(ele);
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor(ele);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    []
  );

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const ele = innerRef.current;
      if (!ele) {
        return;
      }
      const touch = e.touches[0];
      const startPos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        ele.scrollTop = startPos.top - dy;
        ele.scrollLeft = startPos.left - dx;
        updateCursor(ele);
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor(ele);
      };

      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    },
    []
  );

  const updateCursor = (ele: HTMLDivElement) => {
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
  };

  const resetCursor = (ele: HTMLDivElement) => {
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  };

  // useEffect(() => {
  //   if (!innerRef.current) return;

  //   innerRef.current.addEventListener('wheel', (e) => e.preventDefault(), {
  //     passive: false,
  //   });
  // }, [innerRef]);

  return (
    <Box position="relative" {...containerProps}>
      <Flex
        overflow={{ base: 'auto', '2xl': 'hidden' }}
        _hover={{ overflow: 'auto' }}
        userSelect="none"
        ref={innerRef}
        onMouseDown={handleMouseDown}
        // onTouchStart={handleTouchStart}
        position="absolute"
        width="100%"
        height="100%"
        scrollSnapType="x mandatory"
        scrollBehavior="smooth"
        {...containerProps}
      >
        {showOverlay && (
          <Box
            w={innerRef.current?.scrollWidth}
            h="100%"
            position="absolute"
            zIndex="20"
          />
        )}
        {children}
      </Flex>
      <Box
        position="absolute"
        width="112px"
        height="100%"
        display={{
          base: 'none',
          md: 'block',
        }}
        zIndex="20"
        right={{ base: '-1.5rem', xl: '-1rem' }}
        userSelect="none"
      >
        <Image
          alt="scroll-effect"
          src="/assets/scroll-right-effect.svg"
          fill
          unoptimized
          draggable={false}
        />
      </Box>
    </Box>
  );
});

DragToScrollContainer.displayName = 'DragToScrollContainer';

export default DragToScrollContainer;
